<template>
  <div class="page-slideout">
    <div class="section section-separate">
      <div class="container">
        <form class="content register-content">
          <div class="nav-responsive">
            <ul class="nav nav-register fiveitems">
              <template v-for="(item, index) in itemsStep">
                <li
                  :key="index"
                  :class="item.active ? 'active' : item.finish ? 'done' : ''"
                  @click="
                    item.finish && stepdetail < 5 && changeStep(item.linkItems)
                  "
                >
                  <a href="#">
                    <span class="icons icon-checkmark"></span>
                    <span class="number">{{ item.step }}</span>
                    <span class="text">{{ item.name }}</span>
                  </a>
                </li>
              </template>
            </ul>
          </div>
          <div class="scroll-wrapper scrollbar-inner has-menu overflow-y-auto">
            <div class="form-box w-630">
              <h3 class="title-md">{{ $t("DetailStep1.title") }}</h3>

              <div class="form-group">
                <label class="light">{{
                  $t("DetailStep1.title_acc_name")
                }}</label>
                <input
                  id="propDetailName"
                  type="text"
                  class="form-control"
                  name=""
                  v-model="propertyDetail.acc_name"
                />
                <span class="error-text-input" v-if="!propertyDetail.acc_name">{{
                  $t("Alert.pleasefilldata")
                }}</span>
              </div>

              <div class="form-group">
                <label class="light">{{
                  $t("DetailStep1.title_address")
                }}</label>
                <input
                  id="propDetailAddress"
                  type="text"
                  class="form-control"
                  name=""
                  v-model="propertyDetail.address"
                />
                <span class="error-text-input" v-if="!propertyDetail.address">{{
                  $t("Alert.pleasefilldata")
                }}</span>
              </div>

              <div class="form-group">
                <label class="light"
                  >{{ $t("DetailStep1.title_unitnum") }}
                  <span class="graylight">{{
                    $t("DetailStep1.label_unitnum")
                  }}</span></label
                >
                <input
                  id="propDetailUnitNumber"
                  type="text"
                  class="form-control"
                  name=""
                  v-model="propertyDetail.unitnumber"
                />
              </div>

              <div class="form-group">
                <label class="light">{{
                  $t("DetailStep1.title_country")
                }}</label>
                <div
                  class="dropdown select"
                  @click="getfocus('selectcountry')"
                  @mouseleave="mouseOver('selectcountry')"
                >
                  <a
                    data-toggle="dropdown"
                    data-display="static"
                    id="selectcountry"
                  >
                    <v-autocomplete
                      v-model="propertyDetail.country"
                      :items="itemsCountry"
                      item-text="name"
                      item-value="value"
                      label=""
                      placeholder=""
                      :no-data-text="$t('SignupStep.nodata')"
                      color="pink"
                      solo
                      flat
                      dense
                      single-line
                      hide-details
                      @mouseenter="getfocus('selectcountry')"
                      @mouseleave="mouseOver('selectcountry')"
                    ></v-autocomplete>
                  </a>
                </div>
                <span class="error-text-input" v-if="!propertyDetail.country">{{
                  $t("Alert.pleasefilldata")
                }}</span>
              </div>

              <div class="form-group">
                <label class="light">{{
                  $t("DetailStep1.title_province")
                }}</label>
                <div
                  class="dropdown select"
                  @click="getfocus('selectprovince')"
                  @mouseleave="mouseOver('selectprovince')"
                >
                  <a
                    data-toggle="dropdown"
                    data-display="static"
                    id="selectprovince"
                  >
                    <v-autocomplete
                      v-model="propertyDetail.province"
                      :items="itemsProvince"
                      item-text="province"
                      item-value="province_code"
                      label=""
                      placeholder=""
                      :no-data-text="$t('SignupStep.nodata')"
                      color="pink"
                      solo
                      flat
                      dense
                      single-line
                      hide-details
                      @mouseenter="getfocus('selectprovince')"
                      @mouseleave="mouseOver('selectprovince')"
                      @change="selectProvinceFunc()"
                    ></v-autocomplete>
                  </a>
                </div>
                <span class="error-text-input" v-if="!propertyDetail.province">{{
                  $t("Alert.pleasefilldata")
                }}</span>
              </div>

              <div class="form-group">
                <label class="light">{{
                  $t("DetailStep1.title_district")
                }}</label>
                <div
                  class="dropdown select"
                  @click="getfocus('selectamphoe')"
                  @mouseleave="mouseOver('selectamphoe')"
                >
                  <a
                    data-toggle="dropdown"
                    data-display="static"
                    id="selectamphoe"
                  >
                    <v-autocomplete
                      v-model="propertyDetail.amphoe"
                      :items="itemsAmphoe"
                      item-text="amphoe"
                      item-value="amphoe_code"
                      label=""
                      placeholder=""
                      :no-data-text="$t('SignupStep.nodata')"
                      color="pink"
                      solo
                      flat
                      dense
                      single-line
                      hide-details
                      @mouseenter="getfocus('selectamphoe')"
                      @mouseleave="mouseOver('selectamphoe')"
                      @change="selectAmphoeFunc()"
                    ></v-autocomplete>
                  </a>
                </div>
                <span class="error-text-input" v-if="!propertyDetail.amphoe">{{
                  $t("Alert.pleasefilldata")
                }}</span>
              </div>

              <div class="form-group">
                <label class="light">{{
                  $t("DetailStep1.title_subdistrict")
                }}</label>
                <div
                  class="dropdown select"
                  @click="getfocus('selectsubdistrict')"
                  @mouseleave="mouseOver('selectsubdistrict')"
                >
                  <a
                    data-toggle="dropdown"
                    data-display="static"
                    id="selectsubdistrict"
                  >
                    <v-autocomplete
                      v-model="propertyDetail.subdistrict"
                      :items="itemsDistrict"
                      item-text="district"
                      item-value="district_code"
                      label=""
                      placeholder=""
                      :no-data-text="$t('SignupStep.nodata')"
                      color="pink"
                      solo
                      flat
                      dense
                      single-line
                      hide-details
                      @mouseenter="getfocus('selectsubdistrict')"
                      @mouseleave="mouseOver('selectsubdistrict')"
                      @change="selectSubDistrictFunc()"
                    ></v-autocomplete>
                  </a>
                </div>
                <span
                  class="error-text-input"
                  v-if="!propertyDetail.subdistrict"
                  >{{ $t("Alert.pleasefilldata") }}</span
                >
              </div>

              <div class="form-group">
                <label class="light">{{
                  $t("DetailStep1.title_postcode")
                }}</label>
                <input
                  id="propPostcode"
                  type="text"
                  class="form-control"
                  name=""
                  v-model="propertyDetail.postcode"
                />
                <span class="error-text-input" v-if="!propertyDetail.postcode">{{
                  $t("Alert.pleasefilldata")
                }}</span>
              </div>
            </div>
          </div>
          <div class="form-buttons">
            <button
              type="button"
              class="btn gl-bt hover_btn"
              style="text-transform: capitalize !important"
              :disabled="!cangonextstep"
              @click="updatePropertyDetail()"
            >
              <span>{{ $t("DetailStep1.btn_next") }}</span>
            </button>
          </div>
        </form>
        <div class="sidebar">
          <div class="inner d-flex">
            <a
              :href="pathrightphoto"
              class="img-exemple iphone-model"
              data-fancybox
            >
              <img
                :src="pathrightphoto"
                style="
                  border-radius: 0.938rem !important;
                  object-fit: cover !important;
                "
                alt=""
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/EventBus";
import swal from "sweetalert";

export default {
  data: () => ({
    stepdetail: 1,
    propertyID: "",
    userToken: "",
    itemsCountry: [
      {
        _id: 0,
        name: "ไทย",
        value: "th",
      },
    ],
    itemsProvince: [],
    itemsAmphoe: [],
    itemsDistrict: [],
    propertyDetail: {
      acc_name: "",
      address: "",
      unitnumber: "",
      postcode: "",
      district: "",
      amphoe: "",
      province: "",
      country: "",
    },
    cangonextstep: false,
    itemsStep: [],
    propertyData: {},
    pathrightphoto: "",
    value_element: "",
  }),
  created() {
    const self = this;
    // self.itemsProvince = rawAllCountry
    // self.itemsAmphoe = rawAllCountry
    // self.itemsDistrict = rawAllCountry
    self.propertyID = self.$route.params.propertyId;
    self.userToken = self.$route.params.userToken;
    self.initChangeLanguage();
  },
  mounted() {
    EventBus.$on("sendPropertyDetailData", this.getPropertyDetail);
    EventBus.$on("refreshRightPhoto", this.initChangeLanguage);
    this.colorHover();
  },
  watch: {
    propertyDetail() {
      const self = this;
      self.initSetData();
    },
    "propertyDetail.acc_name"() {
      const self = this;
      self.initSetData();
    },
    "propertyDetail.address"() {
      const self = this;
      self.initSetData();
    },
    "propertyDetail.postcode"() {
      const self = this;
      self.initSetData();
    },
    "propertyDetail.subdistrict"() {
      const self = this;
      self.initSetData();
    },
    "propertyDetail.amphoe"() {
      const self = this;
      self.initSetData();
    },
    "propertyDetail.province"() {
      const self = this;
      self.initSetData();
    },
    "propertyDetail.country"() {
      const self = this;
      self.initSetData();
    },
  },
  methods: {
    colorHover() {
      const button = document.querySelector(".hover_btn");
      button.addEventListener("mousemove", (e) => {
        const { x, y } = button.getBoundingClientRect();
        button.style.setProperty("--x", e.clientX - x);
        button.style.setProperty("--y", e.clientY - y);
      });
    },
    getfocus(val) {
      var element = document.getElementById(val);
      if (this.value_element === "") {
        element.classList.add("border-pink-focus");
        this.value_element = val;
      } else if (this.value_element) {
        document
          .getElementById(this.value_element)
          .classList.remove("border-pink-focus");
        element.classList.add("border-pink-focus");
        this.value_element = val;
      }
    },
    mouseOver(val) {
      var element = document.getElementById(val);
      if (this.value_element) {
        document
          .getElementById(this.value_element)
          .classList.remove("border-pink-focus");
        element.classList.remove("border-pink-focus");
        this.value_element = "";
      }
    },
    initChangeLanguage() {
      const self = this;
      self.initStepData();
      self.initCountry();
      // self.selectProvinceFunc();
      // self.selectAmphoeFunc();
    },
    async initCountry() {
      const self = this;
      EventBus.$emit("loadingtillend");
      var tempCountry = [];
      var temp = [];
      self.loadingCountry = true;
      self.loadingProvince = true;
      self.itemsCountry = [];
      self.itemsProvince = [];
      self.itemsAmphoe = [];
      self.itemsDistrict = [];
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API + `/setting/country`,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") ||
                self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          if (res?.data?.result?.length) {
            res?.data?.result?.map((el) => {
              tempCountry.push({
                _id: el._id || 0,
                name: el.value || "ไทย",
                value: el._id || "th",
                province: el.province || [],
              });
              if (el?.province?.length) {
                el?.province?.map((ele) => {
                  temp.push({
                    district: ele?.subdistrict || "",
                    amphoe: ele?.district || "",
                    province: ele?.province || "",
                    zipcode: ele?.zipcode || "",
                    district_code: ele?.subdistrict_code || "",
                    amphoe_code: ele?.district_code || "",
                    province_code: ele?.province_code || "",
                  });
                });
              }
            });
          }
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
      } finally {
        EventBus.$emit("endloading");
        self.loadingCountry = false;
        self.loadingProvince = false;
        self.itemsCountry = tempCountry;
        self.itemsProvince = temp;
        self.itemsAmphoe = temp;
        self.itemsDistrict = temp;
        self.initPropertyDetail();
      }
    },
    selectProvinceFunc() {
      const self = this;
      EventBus.$emit("loadingtillend");
      self.loadingAmphoe = true;
      self.itemsAmphoe = [];
      var temp = [];

      self?.itemsProvince?.map((el) => {
        if (
          el?.province_code == self?.propertyDetail?.province &&
          self?.propertyDetail?.province &&
          self?.propertyDetail?.province !== undefined
        ) {
          temp.push(el);
        }
      });

      EventBus.$emit("endloading");
      self.loadingAmphoe = false;
      self.itemsAmphoe = temp;
    },
    selectAmphoeFunc() {
      const self = this;
      EventBus.$emit("loadingtillend");
      self.loadingDistrict = true;
      var temp = [];
      self?.itemsAmphoe?.map((el) => {
        if (
          el.province_code === self?.propertyDetail?.province &&
          el.amphoe_code === self?.propertyDetail?.amphoe &&
          self?.propertyDetail?.province &&
          self?.propertyDetail?.amphoe
        ) {
          temp.push(el);
        }
      });
      EventBus.$emit("endloading");
      self.loadingDistrict = false;
      self.itemsDistrict = temp;
    },
    selectSubDistrictFunc() {
      const self = this;
      EventBus.$emit("loadingtillend");
      var temp = self?.itemsAmphoe?.find(
        (p) =>
          p?.province_code === self?.propertyDetail?.province &&
          p.amphoe_code === self?.propertyDetail?.amphoe &&
          p.district_code === self?.propertyDetail?.subdistrict &&
          self?.propertyDetail?.province &&
          self?.propertyDetail?.amphoe &&
          self?.propertyDetail?.subdistrict
      );
      EventBus.$emit("endloading");
      if (temp?.zipcode) self.propertyDetail.postcode = temp?.zipcode || "";
    },
    async initStepData() {
      const self = this;
      self.itemsStep = [];
      var temp = {};
      EventBus.$emit("loadingPage");
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API + `/property?_id=${self.propertyID}`,
          {
            headers: {
              Authorization:
                self.userToken ||
                localStorage.getItem("Token") ||
                self.$store.state.resSignUpData.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") ||
                self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          // console.log(res?.data?.result[0])
          res?.data?.result?.map((el) => {
            if (el._id == self.propertyID) temp = el;
          });
          self.initDataStep = temp;
          self.keepUserID = res?.data?.result[0]?.user?._id;
          if (res?.data?.result?.length) {
            self.itemsStep = [
              {
                name: self.$t("SignupStep.stepdetail1"),
                active: (self.stepdetail == 1 && true) || false,
                finish: res?.data?.result[0]?.property_detail?.success || false,
                linkItems: {
                  link: `signup/propertydetail/${self.propertyID}`,
                  name: "SignupPropertyDetailScreen",
                  params: { propertyId: self.propertyID },
                },
                step: 1,
              },
              {
                name: self.$t("SignupStep.stepdetail2"),
                active: (self.stepdetail == 2 && true) || false,
                finish: res?.data?.result[0]?.owner_detail?.success || false,
                linkItems: {
                  link: `signup/ownerdetail/${self.propertyID}`,
                  name: "SignupOwnerDetailScreen",
                  params: { propertyId: self.propertyID },
                },
                step: 2,
              },
              {
                name: self.$t("SignupStep.stepdetail3"),
                active: (self.stepdetail == 3 && true) || false,
                finish: res?.data?.result[0]?.manager_detail?.success || false,
                linkItems: {
                  link: `signup/managerdetail/${self.propertyID}`,
                  name: "SignupManagerDetailScreen",
                  params: { propertyId: self.propertyID },
                },
                step: 3,
              },
              {
                name: self.$t("SignupStep.stepdetail4"),
                active: (self.stepdetail == 4 && true) || false,
                finish: self.stepdetail == 5,
                linkItems: {
                  link: `signup/confirmation/${self.propertyID}`,
                  name: "SignupConfirmationScreen",
                  params: { propertyId: self.propertyID },
                },
                step: 4,
              },
            ];
          }
        }
      } catch (error) {
        console.log(error?.response?.data?.message || error);
      } finally {
        self.getStepData(self.itemsStep);
      }
    },
    getStepData(items) {
      const self = this;
      self.itemsStep = items;
      self.changePhotoRight();
    },
    changePhotoRight() {
      const self = this;
      self.pathrightphoto = `${process.env.VUE_APP_PIC_API}/src/aigent_signup_bg.jpg`;
    },
    async initPropertyDetail() {
      const self = this;
      var temp = [];
      EventBus.$emit("loadingPage");
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API + `/property?_id=${self.propertyID}`,
          {
            headers: {
              Authorization:
                self.userToken ||
                localStorage.getItem("Token") ||
                self.$store.state.resSignUpData.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") ||
                self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          temp = res.data.result;
          self.propertyData = res.data.result[0];
        }
      } catch (error) {
        console.log(error?.response?.data?.message || error);
      } finally {
        self.getPropertyDetail(temp);
      }
    },
    initSetData() {
      const self = this;
      if (
        self.propertyDetail.acc_name &&
        self.propertyDetail.address &&
        self.propertyDetail.postcode &&
        self.propertyDetail.subdistrict &&
        self.propertyDetail.amphoe &&
        self.propertyDetail.province &&
        self.propertyDetail.country
      ) {
        self.cangonextstep = true;
      } else {
        self.cangonextstep = false;

        var elementDetailName = document.getElementById(`propDetailName`);
        var elementDetailAddress = document.getElementById(`propDetailAddress`);
        var elementDetailSelectCountry =
          document.getElementById(`selectcountry`);
        var elementDetailProvince = document.getElementById(`selectprovince`);
        var elementDetailAmphoe = document.getElementById(`selectamphoe`);
        var elementDetailSubdistrict =
          document.getElementById(`selectsubdistrict`);
        var elementDetailPostcode = document.getElementById(`propPostcode`);

        if (!self.propertyDetail.acc_name) {
          elementDetailName.classList.add("border-pink-focus");
        } else {
          elementDetailName?.classList?.remove("border-pink-focus");
        }
        if (!self.propertyDetail.address) {
          elementDetailAddress.classList.add("border-pink-focus");
        } else {
          elementDetailAddress?.classList?.remove("border-pink-focus");
        }
        if (!self.propertyDetail.country) {
          elementDetailSelectCountry.classList.add("border-pink-focus");
        } else {
          elementDetailSelectCountry?.classList?.remove("border-pink-focus");
        }
        if (!self.propertyDetail.province) {
          elementDetailProvince.classList.add("border-pink-focus");
        } else {
          elementDetailProvince?.classList?.remove("border-pink-focus");
        }
        if (!self.propertyDetail.amphoe) {
          elementDetailAmphoe.classList.add("border-pink-focus");
        } else {
          elementDetailAmphoe?.classList?.remove("border-pink-focus");
        }
        if (!self.propertyDetail.subdistrict) {
          elementDetailSubdistrict.classList.add("border-pink-focus");
        } else {
          elementDetailSubdistrict?.classList?.remove("border-pink-focus");
        }
        if (!self.propertyDetail.postcode) {
          elementDetailPostcode.classList.add("border-pink-focus");
        } else {
          elementDetailPostcode?.classList?.remove("border-pink-focus");
        }
      }
    },
    getPropertyDetail(data) {
      const self = this;
      EventBus.$emit("loadingtillend");
      const tempPropertyCountry = self?.itemsCountry?.find(
        (p) =>
          p.name === data[0]?.property_detail?.country ||
          p.name === data[0]?.contact?.country
      );
      const tempPropertyProvince = self?.itemsProvince?.find((p) =>
        data[0]?.property_detail?.province
          ? p.province === data[0]?.property_detail?.province
          : p.province === data[0]?.contact?.province
      );
      const tempPropertyAmphoe = self?.itemsAmphoe?.find((p) =>
        data[0]?.property_detail?.district
          ? p.amphoe === data[0]?.property_detail?.district
          : p.amphoe === data[0]?.contact?.district
      );
      // console.log("data: ", data[0]);
      // console.log("tempPropertyCountry: ", tempPropertyCountry);
      // console.log("tempPropertyProvince: ", tempPropertyProvince);
      // console.log("tempPropertyAmphoe: ", tempPropertyAmphoe);
      var tempItemsAmphoe = [];
      var tempItemsCity = [];
      var tempPropertyCity = null;
      self?.itemsProvince?.map((el) => {
        if (data[0]?.property_detail?.province) {
          if (data[0]?.property_detail?.district) {
            if (el.province == data[0]?.property_detail?.province) {
              tempItemsAmphoe.push(el);
            }
            if (
              el.province == data[0]?.property_detail?.province &&
              el.amphoe == data[0]?.property_detail?.district
            ) {
              tempItemsCity.push(el);
              if (el.district == data[0]?.property_detail?.city)
                tempPropertyCity = el;
            }
          }
        } else {
          if (el.province == data[0]?.contact?.province) {
            tempItemsAmphoe.push(el);
          }
          if (
            el.province == data[0]?.contact?.province &&
            el.amphoe == data[0]?.contact?.district
          ) {
            tempItemsCity.push(el);
            if (el.district == data[0]?.contact?.subdistrict)
              tempPropertyCity = el;
          }
        }
      });
      self.itemsAmphoe = tempItemsAmphoe;
      self.itemsDistrict = tempItemsCity;
      // console.log("tempItemsCity: ", tempItemsCity, tempPropertyCity);
      // console.log(data[0].property_detail);
      if (data[0]?.property_detail?.success) {
        self.propertyDetail = {
          acc_name: data[0]?.property_detail?.property_name || "",
          address: data[0]?.property_detail?.address || "",
          unitnumber: data[0]?.property_detail?.unit_number || "",
          postcode:
            tempPropertyCity?.zipcode ||
            data[0]?.property_detail?.postcode ||
            "",
          subdistrict:
            tempPropertyCity?.district_code ||
            data[0]?.property_detail?.city ||
            "",
          amphoe:
            tempPropertyAmphoe?.amphoe_code || data[0]?.contact?.district || "",
          province:
            tempPropertyProvince?.province_code ||
            data[0]?.contact?.province ||
            "",
          country:
            tempPropertyCountry?.value ||
            data[0]?.property_detail?.country ||
            "",
        };
      } else if (data[0]?.contact) {
        self.propertyDetail = {
          acc_name: data[0]?.name || "",
          address: data[0]?.contact?.address_line_1 || "",
          unitnumber: data[0]?.contact?.address_line_2 || "",
          postcode:
            tempPropertyCity?.zipcode || data[0]?.contact?.post_code || "",
          subdistrict:
            tempPropertyCity?.district_code ||
            data[0]?.contact?.subdistrict ||
            "",
          amphoe:
            tempPropertyAmphoe?.amphoe_code || data[0]?.contact?.district || "",
          province:
            tempPropertyProvince?.province_code ||
            data[0]?.contact?.province ||
            "",
          country:
            tempPropertyCountry?.value || data[0]?.contact?.country || "",
        };
      }

      EventBus.$emit("endloading");
    },
    updatePropertyDetail() {
      const self = this;
      const obj = {
        property_name: self.propertyDetail.acc_name,
        address: self.propertyDetail.address,
        unit_number: self.propertyDetail.unitnumber,
        city: self.propertyDetail.subdistrict.toString(),
        district: self.propertyDetail.amphoe.toString(),
        province: self.propertyDetail.province.toString(),
        country: self.propertyDetail.country,
        postcode: self.propertyDetail.postcode.toString(),
      };
      // if (self?.propertyDetail?.postcode) {
      //   const temp = self?.itemsProvince?.find(
      //     (p) => p?.zipcode == self?.propertyDetail?.postcode
      //   );
      //   console.log("temp: ", temp);
      // }
      // console.log("update obj: ", obj);
      if (
        !obj.property_name ||
        !obj.address ||
        !obj.city ||
        !obj.country ||
        !obj.postcode
      ) {
        swal(
          self.$t("Alert.warn_title"),
          self.$t("Alert.fillinfo"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      } else {
        self.updatePropertyFunc(obj);
      }
    },
    async updatePropertyFunc(obj) {
      const self = this;
      EventBus.$emit("loadingPage");
      var item = {
        link: `signup/ownerdetail/${self.propertyID}`,
        name: "SignupOwnerDetailScreen",
        params: { propertyId: self.propertyID },
      };
      try {
        const res = await self.axios.put(
          process.env.VUE_APP_API +
            `/property/property_detail?property_id=${self.propertyID}`,
          obj,
          {
            headers: {
              Authorization:
                self.userToken ||
                localStorage.getItem("Token") ||
                self.$store.state.resSignUpData.access_token,
            },
          }
        );
        if (res.status == 200) {
          self.changeStep(item);
          EventBus.$emit("changeDetailStep", 2);
        } else {
          swal(
            self.$t("Alert.warn_title"),
            self.$t("Alert.tryagain"),
            self.$t("Alert.warn_label"),
            {
              button: false,
              timer: 3000,
            }
          );
        }
      } catch (error) {
        console.log(error?.response?.data?.message || error);
      }
    },
    changeStep(linkItems) {
      EventBus.$emit("changePathname", linkItems);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../styles/bootstrap/global.css";
@import "../../../styles/bootstrap/jquery.scrollbar.css";

.btn.gl-bt {
  overflow: hidden;
  border: 0;
  background-color: var(--pink);
  padding: 0 0.625rem;
}

.btn.gl-bt:hover {
  background-color: #652b80;
}

.btn.gl-bt::before {
  top: calc(var(--y, 0) * 1px - 50px);
  left: calc(var(--x, 0) * 1px - 50px);
  --size: 0;
  content: "";
  position: absolute;
  width: var(--size);
  height: var(--size);
  background: radial-gradient(circle closest-side, #e8107f, transparent);
  transform: translate(-50%, -50%);
  transition: width 0.2s ease, height 0.2s ease;
}

.gl-bt:hover:before {
  --size: 350px;
}

.border-pink-focus {
  border-color: var(--pink) !important;
}

.btn.btn-searchmap {
  background-color: var(--pink) !important;
  border-color: var(--pink) !important;
}

.btn.btn-searchmap:hover {
  background-color: #ea084a !important;
  border-color: #ea084a !important;
}

.btn-cancel-searchmap:hover {
  background-color: #777 !important;
  border-color: #777 !important;
}

.btn-cancel-searchmap:hover span {
  color: #fff !important;
}
</style>
